import { isNullUndefinedEmpty, truncateToWord } from '../helpers/helpers';

import AjaxTable from '../helpers/ajaxTable';
import { displayErrorMessage } from '../helpers/displayError';
import userStateManager from '../helpers/stateManager';

export const component = async () => {
    const momentModule = await import('moment-mini');
    window.moment = momentModule.default;

    const $myIncidentsTableElement = $('#my-incidents-table');
    const $myIncidentsProfileSummary = $('#my-incidents-profile-summary');

    userStateManager.getUserProfileData(function (data) {
        if (data && data.apiProblemOccured) {
            displayErrorMessage($myIncidentsTableElement.parent());
            displayErrorMessage($myIncidentsProfileSummary, true, false);
            return;
        }

        if (!isNullUndefinedEmpty(data.Incidents)) {
            const fullListing = $myIncidentsTableElement.data('full');
            const max = 5;

            //Format data ready to map to table
            var incidentsData = data.Incidents.map((item) => {
                const dateFormatted = !isNullUndefinedEmpty(item.DateOccurred) ? moment(item.DateOccurred).format('DD/MM/YYYY') : '-';

                return [dateFormatted, item.BguName, item.Type, item.Location];
            });

            if (fullListing) {
                new AjaxTable($myIncidentsTableElement, incidentsData);
            } else {
                new AjaxTable($myIncidentsTableElement, incidentsData.slice(0, max));

                //show a button if more than max
                if (incidentsData.length > max) {
                    $('.button-wrapper.my-incidents-button-wrapper').css({
                        display: 'block',
                    });
                }
            }

            //Setup my profile summary if it exists
            if ($myIncidentsProfileSummary.length > 0) {
                const listHtml = data.Incidents.slice(0, max - 1).map((item) => getProfileSummaryIncidentsListItem(item));
                $myIncidentsProfileSummary.html(listHtml);
            } else {
                $myIncidentsProfileSummary.html('<p>It looks like you do not yet have any incidents</p>');
            }
        }
    });
};

function getProfileSummaryIncidentsListItem(content) {
    const summary = truncateToWord(content.Type, 50);
    return `
		<div class="my-latest-incidents__row">
			<div class="incident-date">${moment(content.DateOccurred).format('DD/MM/YYYY')}</div>
			<div class="incident-summary">${summary}</div>
		</div>
	`;
}
